import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../layouts";
import SEO from "../layouts/seo";
import HeaderTitleBlog from '../components/Typography/HeaderTitleBlog';
import {
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useIntl } from "gatsby-plugin-intl";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '160px',
    marginBottom: '60px',
  },
}));

const BlogPage = ({ data }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "navigation.projects" })} />
      <Container maxWidth="lg" className={classes.root}>
        <h2>{intl.formatMessage({ id: "navigation.projects" })}</h2>
        <Grid container alignItems="stretch" spacing={3}>
          { data.allMarkdownRemark.edges.map(document => (
            <Grid item xs={12} sm={12} md={4} key={document.node.id}>
                <Link className={classes.link} to={`${document.node.frontmatter.path}`}>
                  <Img
                    fluid={document.node.frontmatter.featuredImage.childImageSharp.fluid}
                    alt={ document.node.frontmattertitle }
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </Link>
                <HeaderTitleBlog to={document.node.frontmatter.path} title={ document.node.frontmatter.title } />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export default BlogPage;

export const pageQuery = graphql`
  query ProjectsQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`