import React from 'react';
import { Link } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'baseline',

    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      transition: 'all .3s ease-in-out',

      '&:hover': {
        color: '#2AA7DE',
      },
    }
  },
  title: {
    fontSize: '18px',
    margin: '10px 0',
  },
}));
const HeaderTitleBlog = ({ to, title }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.header}>
      <h3 className={classes.title}>
        <Link to={to}>{title}</Link>
      </h3>
    </div>
  )
}

HeaderTitleBlog.defaultProps = {
  to: '/',
  title: '',
};

HeaderTitleBlog.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
}

export default HeaderTitleBlog;
